@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

.ag-theme-balham {
    @include ag-theme-balham(( font-family: ('itc-american-typewriter', serif) ));
}

.gridbuttonbar {
    display: flex;
    align-content: center;
}

.gridiconbutton {
    padding: 5px 8px;
    margin: 2px 0px;
    border-radius: 0px;
    border-width: 1px;
    border-style: inset;
    border-color: lightgray;    
    display: inline-flex;
    align-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #0227b3;
}

.gridiconbutton:hover {
    background-color: #3452c2;
}

.gridiconbutton.danger {
    background-color: #c81b03;
}

.gridiconbutton.danger:hover {
    background-color: #d34835;
}

.gridiconbutton.caution {
    background-color: #ffd534;
    color: #000000;
}

.gridiconbutton.caution:hover {
    background-color: #ffdd5c;
    color: #000000;
}

.gridiconbutton:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.gridiconbutton:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}