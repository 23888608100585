body {
    margin: 0;
    font-family: 'itc-american-typewriter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-variation="primary"].amplify-button {
    background-color: #ffd534 !important;
    color: #000000 !important;
}
[data-variation="primary"].amplify-button:hover {
    background-color: #b29524 !important;
    color: #ffffff !important;
}
